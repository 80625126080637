import classnames from "classnames";
import he from "he";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styles from "./TextBubble.module.scss";

export default class TextBubble extends Component {
    static propTypes = {
        text: PropTypes.string,
        isClient: PropTypes.bool,
        mobileView: PropTypes.bool
    };

    static defaultProps = {
        text: "",
        isClient: false,
    };

    render() {
        let bubbleStyles = styles.agent_bubble;
        if (this.props.isClient) {
            bubbleStyles = styles.response_bubble;
        }
        let mobile = {};
        if (this.props.mobileView) {
            mobile = styles.mobile;
        }

        return (
            <div className={classnames(styles.text_bubble, bubbleStyles, mobile)}>
                {this.props.isClient ? (
                    <span>{he.decode(this.props.text)}</span>
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
                )}
            </div>
        );
    }
}
