/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import root from "../reducers/root";

export default function configureStore() {
    const store = createStore(root, composeWithDevTools(applyMiddleware(thunk)));

    return store;
}
