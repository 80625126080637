/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { Button } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { ReactComponent as DragIcon } from "../../../../resources/svg/drag_and_drop_icon.svg";
import { ReactComponent as GreenTick } from "../../../../resources/svg/greenTick.svg";
import ChoiceDTO from "../../../../types/Choice";
import styles from "./Choice.module.scss";
import getUrlParams from "../../../../constants/getUrlParams";
import useSelector from "../../../../hooks/useSelector";

type Props = {
    choice: ChoiceDTO;
    className?: string;
    selected?: boolean;
    draggable?: boolean;
    onPress?: (choice: ChoiceDTO) => void;
};

export default function Choice(props: Props) {
    const { choice, selected, draggable } = props;
    const { value } = choice;

    const onPress = () => {
        props.onPress?.(choice);
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { preview_survey_id } = getUrlParams();
    const mobileView = !!((useSelector((state) => state.info.mobileToggle)) && preview_survey_id);
    const className = classNames(
        styles.choice,
        {
            [styles.selected]: selected,
            [styles.draggable]: draggable,
           [styles.mobile]: mobileView
        },
        props.className,
    );

    const content = (
        <div className={styles.content}>
            <div className={styles.middle}>
                <span dangerouslySetInnerHTML={{__html: value}}/>
            </div>
            {draggable && <div className={styles.right}><DragIcon /></div>}
        </div>
    );

    if (draggable) {
        return <div className={className}>{content}</div>;
    }

    return (
        <Button onClick={onPress} className={className} disableRipple>
            {content}
        </Button>
    );
}
