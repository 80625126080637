/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import classNames from "classnames";
import React from "react";
import { MessageGroup } from "../../../../redux/reducers/messages";
import { ReactComponent as AgentK } from "../../../../resources/svg/agentKStatic.svg";
import { ReactComponent as AgentR } from "../../../../resources/svg/agentRStatic.svg";
import Message, { From, MessageType } from "../../../../types/Message";
import MediaBubble from "../../mediaBubble/MediaBubble";
import LoadingBlock from "../../messageBlocks/loadingBlock/LoadingBlock";
import TextBlock from "../../messageBlocks/textBlock/TextBlock";
import styles from "./MessageBlock.module.scss";
import getUrlParams from "../../../../constants/getUrlParams";
import useSelector from "../../../../hooks/useSelector";
import classnames from "classnames";
import Action from "../../../../types/Action";

type Props = {
    messageGroup: MessageGroup;
    action?: Action | null;
    multiAttributes?: number[];
    messageIndex?: number;
};

const renderMessageBlock = (message: Message, i: number, mobileView: boolean) => {
    switch (message.type) {
        case MessageType.IMAGE:
            return <MediaBubble key={i} image={message.url} mobileView={mobileView}/>;
        case MessageType.ANIMATION:
            return <MediaBubble key={i} video={message.url} text={message.text}/>;
        case MessageType.ANSWER:
        case MessageType.QUESTION_TEXT:
        case MessageType.TEXT: {
            return <TextBlock key={i} message={message} mobileView={mobileView}/>;
        }
        case MessageType.LOADING: {
            return <LoadingBlock key={i} message={message} mobileView={mobileView}/>;
        }

        default: {
            return null;
        }
    }
};

export default function MessageBlock(props: Props) {
    const { messageGroup, action } = props;
    const { from, messages } = messageGroup;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { preview_survey_id } = getUrlParams();
    const mobileView = !!((useSelector((state) => state.info.mobileToggle)) && preview_survey_id);
    const isStickybeak = from === From.STICKYBEAK;
    const className = classNames(styles.message_block, {
        [styles.client_block]: !isStickybeak,
    });

    const mobileIcon = { [styles.mobile_icon]: mobileView };
    return (
        <div className={className}>
            {isStickybeak && (
                <div className={classnames(styles.agent_icon, mobileIcon)}>
                    <AgentK/>
                </div>
            )}
            <div className={styles.children}>{messages.map((m, i) =>(
                !((props.multiAttributes?.includes(props.messageIndex!) && i>0 ) || ((props.action||{})?.questionType === "MULTI_ATTRIBUTE" && i>0)))  &&
                renderMessageBlock(m, i, mobileView)
            )}</div>
            {!isStickybeak && (
                <div className={classnames(styles.agent_icon, mobileIcon)}>
                    <AgentR/>
                </div>
            )}
        </div>
    );
}
