import classnames from "classnames";
import React, { Component } from "react";
import { v4 as uuid } from "uuid";
import styles from "./MediaBubble.module.scss";

export default class MediaBubble extends Component {
    id = uuid();

    componentDidMount() {
        if (this.props.video) {
            const videoElement = document.getElementById(this.id);
            console.log("videoElement", videoElement);

            if (videoElement) {
                videoElement.controls = false;

                if (!videoElement.playing) {
                    videoElement.play();
                }
            }
        }
    }

    render() {
        const caption = this.props.text;
        let bubbleStyles = styles.agent_bubble;
        if (this.props.response) {
            bubbleStyles = styles.response_bubble;
        }
        let mobileStyles = {};
        if (this.props.mobileView) {
            mobileStyles = styles.mobile_view;
        }
        const className = classnames(styles.media_bubble, bubbleStyles, mobileStyles);

        return (
            <div className={className}>
                {this.props.video && (
                    <div className={styles.media_image}>
                        <video
                            id={this.id}
                            autoPlay
                            loop
                            muted
                            playsinline
                            playsInline
                            disableremoteplayback={"true"}
                            controls={false}
                            src={this.props.video}>
                            <source src={this.props.video} type={"video/mp4"} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}

                {this.props.image && (
                    <>
                        <div className={styles.media_image}>
                            <img src={this.props.image} alt={"dynamic media element"} />
                        </div>
                        {caption && <div className={styles.media_caption}>{caption}</div>}
                    </>
                )}
            </div>
        );
    }
}
