/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 13/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import classnames from "classnames";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Loader.module.scss";

export default function Loader(props) {
    const className = classnames(styles.loader, props.className);

    const newProps = omit({ ...props }, "large");

    if (props.large) {
        newProps.width = 65;
        newProps.height = 65;
    }

    return (
        <svg xmlns={"http://www.w3.org/2000/svg"} viewBox={"0 0 32 32"} {...newProps} className={className}>
            <circle transform={"translate(8 0)"} cx={"0"} cy={"16"} r={"0"}>
                <animate
                    attributeName={"r"}
                    values={"0; 4; 0; 0"}
                    dur={"1.2s"}
                    repeatCount={"indefinite"}
                    begin={"0"}
                    keyTimes={"0;0.2;0.7;1"}
                    keySplines={"0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"}
                    calcMode={"spline"}
                />
            </circle>
            <circle transform={"translate(16 0)"} cx={"0"} cy={"16"} r={"0"}>
                <animate
                    attributeName={"r"}
                    values={"0; 4; 0; 0"}
                    dur={"1.2s"}
                    repeatCount={"indefinite"}
                    begin={"0.3"}
                    keyTimes={"0;0.2;0.7;1"}
                    keySplines={"0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"}
                    calcMode={"spline"}
                />
            </circle>
            <circle transform={"translate(24 0)"} cx={"0"} cy={"16"} r={"0"}>
                <animate
                    attributeName={"r"}
                    values={"0; 4; 0; 0"}
                    dur={"1.2s"}
                    repeatCount={"indefinite"}
                    begin={"0.6"}
                    keyTimes={"0;0.2;0.7;1"}
                    keySplines={"0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"}
                    calcMode={"spline"}
                />
            </circle>
        </svg>
    );
}

Loader.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    large: PropTypes.bool,
};

Loader.defaultProps = {
    className: null,
    type: "bubbles",
    height: 36,
    width: 36,
    large: false,
};
