import React, { useState } from "react";
import ChatBot from "./components/routes/chatbot/ChatBot";
import Loading from "./components/routes/loading/Loading";
import setupDevice from "./constants/setupDevice";
import useDispatch from "./hooks/useDispatch";
import useMountEffect from "./hooks/useMountEffect";
import { beginChatbot } from "./redux/actions/chatbot";
import { addStickybeakMessage } from "./redux/actions/messages";
import { v4 as uuid } from "uuid";

export default function Main() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useMountEffect(async () => {
        const pageViewEventId = uuid();
        const viewContentEventId = uuid();
        await setupDevice();
        await dispatch(beginChatbot({ pageViewEventId, viewContentEventId }));
        (window as any).fbq("init", process.env.REACT_APP_FACEBOOK_PIXEL);
        (window as any).fbq("track", "PageView", {}, { eventID: pageViewEventId, client_user_agent: window.navigator.userAgent });
        (window as any).fbq("track", "ViewContent", {}, { eventID: viewContentEventId, client_user_agent: window.navigator.userAgent });
        (window as any).ttq.track("ViewContent");

        setLoading(false);
    }, (err) => {
        setLoading(false);
        dispatch(addStickybeakMessage(err.message || "No survey found"));
    });

    if (loading) {
        return <Loading />;
    }

    return <ChatBot />;
}
