import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { motion } from "framer-motion";
import getUrlParams from "../../../constants/getUrlParams";
import useDispatch from "../../../hooks/useDispatch";
import useSelector from "../../../hooks/useSelector";
import useMountEffect from "../../../hooks/useMountEffect";
import { visitorCount } from "../../../redux/actions/chatbot";
import styles from "./ChatBot.module.scss";
import Preview from "../../widgets/preview/Preview";
import Toolbar from "../../widgets/toolbar/Toolbar";
import Messages from "../../widgets/messages/Messages";
import ResponseBar from "../../widgets/responseBar/ResponseBar";
import GreenTick from "../../../resources/svg/whiteTickGreenCircle.svg";
import AnalyticsAPI from "../../../util/Analytics";

export default function ChatBot() {
    const dispatch = useDispatch();
    const surveyId = useSelector((state) => (state.action || {}).survey) || 0;
    const messages = useSelector((state) => state.messages);
    const action = useSelector((state) => state.action);
    const numberOfQuestions = (useSelector((state) => state.info.numberOfQuestions));
    const questionNumber = useSelector((state) => state.info.currentQuestion);
    const [answeredQuestions, setAnsweredQuestions] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            const objDiv = document.getElementById("chat-window");
            if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        }, 350);
    }, [messages]);

    useEffect(()=>{
        if(messages[messages.length-1].from==="CLIENT"){
            setAnsweredQuestions(questionNumber);
        }
        if(action && action.question<=0 && action.systemQuestion){
            setAnsweredQuestions(numberOfQuestions);
        }
    }, [action]);

    useMountEffect(async () => {
        await dispatch(visitorCount());
        if (surveyId > 0) {
            AnalyticsAPI.pageViewEvent(surveyId);
        }
    });
    console.log(action, numberOfQuestions);

    const progress = (messages.filter((m) => m.from === "CLIENT").length / numberOfQuestions) * 100;
    const questionsProgress = ((answeredQuestions / numberOfQuestions)||0) * 100;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { preview_survey_id } = getUrlParams();
    const mobileView = (useSelector((state) => state.info.mobileToggle)) && preview_survey_id;
    return mobileView ? (
        <div className={styles.chat_bot_mobile}>
            <Preview />
            <Toolbar />

            <div className={styles.chat_bot_window_box_mobile}>
                <div className={styles.content_fade} />
                <div className={styles.chat_bot_window} id={"chat-window"}>
                    <Messages />
                </div>
                <ResponseBar />
                <div className={styles.progress_bar}>
                    {questionsProgress >= 100 ? (
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            transition={{ type: "spring", delay: 0.22 }}
                            className={styles.progress_bar_message}>
                            <img src={GreenTick} alt={""} />
                        </motion.div>
                    ) : null}
                    <ProgressBar
                        height={"5px"}
                        completed={questionsProgress}
                        isLabelVisible={false}
                        bgColor={"#8DD800"}
                        baseBgColor={"#F0F2F2"}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className={styles.chat_bot}>
            <Preview />
            <Toolbar />

            <div className={styles.chat_bot_window_box}>
                <div className={styles.content_fade} />
                <div className={styles.chat_bot_window} id={"chat-window"}>
                    <Messages />
                </div>
                <ResponseBar />
                <div className={styles.progress_bar}>
                    {questionsProgress >= 100 ? (
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            transition={{ type: "spring", delay: 0.22 }}
                            className={styles.progress_bar_message}>
                            <img src={GreenTick} alt={""} />
                        </motion.div>
                    ) : null}
                    <ProgressBar
                        height={"5px"}
                        completed={questionsProgress}
                        isLabelVisible={false}
                        bgColor={"#8DD800"}
                        baseBgColor={"#F0F2F2"}
                    />
                </div>
            </div>
        </div>
    );
}
