import { ReduxDispatch } from "../../hooks/useDispatch";
import Answer from "../../types/Answer";
import { ApplicationState } from "../reducers/root";
import { setAction } from "./action";
import { answerQuestion } from "./chatbot";
import createAction from "./helpers/createAction";
import { setLoading } from "./info";
import { addStickybeakMessage } from "./messages";
import qs from "qs";
import { v4 as uuid } from "uuid";
import AnalyticsAPI from "../../util/Analytics";

export enum Types {
    ADD_ANSWER = "answers:ADD_ANSWER",
}

export const surveyCompletionCode = -1001;

export const addAnswer = createAction(Types.ADD_ANSWER);

export const Actions = {
    [Types.ADD_ANSWER]: addAnswer,
};
export type Actions = typeof Actions;

export function submitAnswer(answer: Answer) {
    return async (dispatch: ReduxDispatch, getState: () => ApplicationState) => {
        const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        const { action, info } = getState();
        const tempAction = action;
        const eventId = uuid();
        dispatch(setLoading(true));
        dispatch(setAction(null));
        dispatch(
            answerQuestion({
                deviceId: window.deviceId!,
                answer,
                numberOfQuestions: info.numberOfQuestions,
                eventId,
                ttclid: search.ttclid ? search.ttclid as string : undefined,
                userAgent: window.navigator.userAgent
            }),
        )
            .then((result) => {
                const resultAction = result.action;

                if (answer) {
                    dispatch(addAnswer(answer));
                }

                dispatch(setLoading(false));

                if (!!resultAction) {
                    if (info.currentQuestion === 1) {
                        AnalyticsAPI.firstQuestionAnsweredEvent(result.surveyId);
                        (window as any).ttq.track("AddToCart");
                        (window as any).fbq("track", "AddToCart", {}, { eventID: eventId, client_user_agent: window.navigator.userAgent });
                    }

                    const progress = info.currentQuestion / info.numberOfQuestions;
                    const hasInitiatedCheckout = localStorage.getItem("initiatedCheckout");

                    let initCheckout = 3;
                    if(info.numberOfQuestions<3){
                        initCheckout = info.numberOfQuestions;
                    }

                    if (info.currentQuestion === initCheckout && hasInitiatedCheckout !== result.surveyId.toString()) {
                        AnalyticsAPI.thirdQuestionAnsweredEvent(result.surveyId);
                        console.log("initiated checkout event");

                        (window as any).ttq.track("InitiateCheckout");
                        (window as any).fbq("track", "InitiateCheckout", { surveyId: result.surveyId }, { eventID: eventId, client_user_agent: window.navigator.userAgent });

                        localStorage.setItem("initiatedCheckout", result.surveyId);

                    }

                    if (resultAction.question === surveyCompletionCode) {
                        console.log("Survey completed event");
                        AnalyticsAPI.surveyCompletedEvent(result.surveyId);
                        // @ts-ignore
                        window.fbq("trackCustom", "SurveyCompleted", { surveyId: result.surveyId }, { eventID: eventId, client_user_agent: window.navigator.userAgent });
                        (window as any).ttq.track("SubmitForm");
                        (window as any).fbq("track", "SubmitApplication", {}, { eventID: eventId, client_user_agent: window.navigator.userAgent });
                    }
                }
                console.log(window.dataLayer);
            })
            .catch((err) => {
                dispatch(addStickybeakMessage(err.message));
                dispatch(setAction(tempAction));
                dispatch(setLoading(false));
            });
    };
}
