export enum From {
    CLIENT = "CLIENT",
    STICKYBEAK = "STICKYBEAK",
}

export enum MessageType {
    ANSWER = "ANSWER",
    QUESTION_TEXT = "QUESTION_TEXT",
    TEXT = "TEXT",
    IMAGE = "IMAGE",
    VIDEO = "VIDEO",
    ANIMATION = "ANIMATION",
    LOADING = "LOADING",
}

interface Message {
    id: string;
    text: string | null;
    url: string | null;
    type: MessageType;
    from: From;
    timestamp: string;
}

export default Message;
