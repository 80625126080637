import Answer from "../../../types/Answer";
import post from "./util/post";
import qs from "qs";

export function beginChatbotAPI(data: { pageViewEventId: string, viewContentEventId: string }): Promise<any> {
    const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    return post("/v1/chatbot/begin", {
        ...data,
        userAgent: window.navigator.userAgent,
        deviceId: localStorage.getItem("deviceId")
    }, {
        params: {
            ttclid: search.ttclid ? search.ttclid as string : ""
        }
    });
}

export function visitorCountAPI(): Promise<any> {
    return post("/v1/chatbot/visitor/count", localStorage.getItem("deviceId"));
}

export function answerQuestionAPI(data: {
    deviceId: string;
    answer: Answer;
    numberOfQuestions: number;
    eventId: string;
    ttclid?: string | undefined;
    userAgent?: string;
}) {
    return post("/v1/chatbot/answer", data);
}
