/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { Actions, Types } from "../actions/info";

const initialState: {
    currentQuestion: number;
    numberOfQuestions: number;
    loading: boolean;
    mobileToggle: boolean;
    deviceId: null | string
} = {
    currentQuestion: 0,
    numberOfQuestions: 0,
    loading: false,
    mobileToggle: true,
    deviceId: null,
};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function info(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_CURRENT_QUESTION: {
            const { payload } = action as ActionValueTypes<Types.SET_CURRENT_QUESTION>;
            return {
                ...state,
                currentQuestion: payload,
            };
        }
        case Types.SET_NUMBER_OF_QUESTIONS: {
            const { payload } = action as ActionValueTypes<Types.SET_NUMBER_OF_QUESTIONS>;
            return {
                ...state,
                numberOfQuestions: payload,
            };
        }
        case Types.SET_LOADING: {
            const { payload } = action as ActionValueTypes<Types.SET_LOADING>;
            return {
                ...state,
                loading: payload,
            };
        }
        case Types.SET_DEVICE_ID: {
            const { payload } = action as ActionValueTypes<Types.SET_DEVICE_ID>;
            return {
                ...state,
                deviceId: payload,
            };
        }
        case Types.SET_MOBILE_TOGGLE: {
            const { payload } = action as ActionValueTypes<Types.SET_MOBILE_TOGGLE>;
            return {
                ...state,
                mobileToggle: payload,
            };
        }
        default: {
            return state;
        }
    }
}
