import React from "react";
import useDispatch from "../../../../hooks/useDispatch";
import { submitAnswer } from "../../../../redux/actions/answers";
import { addClientMessage } from "../../../../redux/actions/messages";
import Action from "../../../../types/Action";
import Answer from "../../../../types/Answer";
import Choice from "../../../../types/Choice";
import ResponseBubble from "../../responseBarBubble/ResponseBubble";
import styles from "./SingleChoice.module.scss";
import {motion} from "framer-motion";

type Props = {
    action: Action;
    mobileView: any;
};

export default function SingleChoice(props: Props) {
    const { action, mobileView } = props;

    const dispatch = useDispatch();

    const onChoiceSelect = (choice: Choice) => {
        const message = choice.value;
        dispatch(addClientMessage(message));

        const answer: Answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choice: choice.id,
            answer: null,
            choices: null,
            skipped: false,
        };

        dispatch(submitAnswer(answer));
    };

    if (!action.choices.length) {
        return null;
    }

    return (
        <motion.div initial={{opacity: 0, y: 10}} animate={{ opacity: 1, y: 0}}
                    transition={{ ease: "easeIn", delay: 0.22  }} exit={{ opacity: 0, y: 10 }}  className={styles.single_choice}>
            {action.choices.map((choice, i) => (
                <ResponseBubble key={i} choice={choice} mobileView={mobileView} onChoiceSelect={onChoiceSelect} />
            ))}
        </motion.div>
    );
}
