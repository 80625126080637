/* eslint-disable */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React from "react";
import getUrlParams from "../../../constants/getUrlParams";
import styles from "./Preview.module.scss";

export default function Preview() {
    const { preview_survey_id } = getUrlParams();

    if (!preview_survey_id) {
        return null;
    }

    return (
        <div className={styles.preview}>
            <span>Previewing Survey - your answers will not be saved.</span>
        </div>
    );
}