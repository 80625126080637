/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import createAction from "./helpers/createAction";

export enum Types {
    SET_CURRENT_QUESTION = "info:SET_CURRENT_QUESTION",
    SET_NUMBER_OF_QUESTIONS = "info:SET_NUMBER_OF_QUESTIONS",
    SET_LOADING = "info:SET_LOADING",
    SET_DEVICE_ID = "info:SET_DEVICE_ID",
    SET_MOBILE_TOGGLE = "info:SET_MOBILE_TOGGLE"
}

export const setCurrentQuestion = createAction<number>(Types.SET_CURRENT_QUESTION);
export const setNumberOfQuestions = createAction<number>(Types.SET_NUMBER_OF_QUESTIONS);
export const setLoading = createAction<boolean>(Types.SET_LOADING);
export const setDeviceId = createAction<string | null>(Types.SET_DEVICE_ID);
export const setMobileToggle = createAction<boolean | null>(Types.SET_MOBILE_TOGGLE);

export const Actions = {
    [Types.SET_CURRENT_QUESTION]: setCurrentQuestion,
    [Types.SET_NUMBER_OF_QUESTIONS]: setNumberOfQuestions,
    [Types.SET_LOADING]: setLoading,
    [Types.SET_DEVICE_ID]: setDeviceId,
    [Types.SET_MOBILE_TOGGLE]: setMobileToggle,
};
export type Actions = typeof Actions;
