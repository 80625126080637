import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Loader from "../loader/Loader";
import styles from "./TextBubble.module.scss";

export default class TextBubble extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        const className = classnames(styles.text_bubble, this.props.className);
        return (
            <div className={className}>
                <Loader />
            </div>
        );
    }
}
