/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { AxiosRequestConfig } from "axios";
import instance from "./instance";

export default async function post<T = any>(url: string, postData?: any, config?: AxiosRequestConfig): Promise<T> {
    const data = await instance.post<T>(url, postData, config);
    return data.data;
}
