import CssBaseline from "@material-ui/core/CssBaseline";
import StylesProvider from "@material-ui/styles/StylesProvider";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import Main from "../Main";
import configureStore from "../redux/store/configureStore";
import Loading from "./routes/loading/Loading";
import { createMuiTheme } from "@material-ui/core";
import { Helmet } from "react-helmet";

const muiTheme = createMuiTheme({
    typography: {
        fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
    spacing: 9,
});

export const store = configureStore();

export default function App() {
    useEffect(() => {
        document.addEventListener("focusout", () => {
            window.scrollTo(0, 0);
        });
        (window as any).ttq.load(process.env.REACT_APP_TIKTOK_PIXEL_ID);
        (window as any).ttq.page();

    }, []);
    return (
        <>
            <Helmet>
                <noscript>
                    {`<img height="1"
                         width="1"
                         style="display:none" alt={""}
                         src={"https://www.facebook.com/tr?id=${process.env.REACT_APP_FACEBOOK_PIXEL}&ev=PageView&noscript=1"} />`}
                </noscript>
            </Helmet>
            <Provider store={store}>
                <ThemeProvider theme={muiTheme}>
                    <StylesProvider injectFirst>
                        <Suspense fallback={<Loading/>}>
                            <CssBaseline/>
                            <Main/>
                        </Suspense>
                    </StylesProvider>
                </ThemeProvider>
            </Provider>
        </>
    );
}
