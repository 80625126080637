/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { v4 as uuid } from "uuid";
import { ReduxDispatch } from "../../hooks/useDispatch";
import Message, { From, MessageType } from "../../types/Message";
import createAction from "./helpers/createAction";

export enum Types {
    ADD_MESSAGE = "messages:ADD_MESSAGE",
    ADD_MESSAGES = "messages:ADD_MESSAGES",
}

export const addMessage = createAction<Message>(Types.ADD_MESSAGE);
export const addMessages = createAction<Message[]>(Types.ADD_MESSAGES);

export const Actions = {
    [Types.ADD_MESSAGE]: addMessage,
    [Types.ADD_MESSAGES]: addMessages,
};
export type Actions = typeof Actions;

export const ADD_MESSAGE = "messages:ADD_MESSAGE";
export const ADD_MESSAGES = "messages:ADD_MESSAGES";

export function addClientMessage(text: string) {
    return (dispatch: ReduxDispatch) => {
        dispatch(addLocalMessages([text], From.CLIENT));
    };
}

export function addClientMessages(messages: string[]) {
    return (dispatch: ReduxDispatch) => {
        dispatch(addLocalMessages(messages, From.CLIENT));
    };
}

export function addStickybeakMessage(text: string) {
    return (dispatch: ReduxDispatch) => {
        dispatch(addLocalMessages([text], From.STICKYBEAK));
    };
}

export function addLocalMessage(text: string, from: From) {
    return (dispatch: ReduxDispatch) => {
        dispatch(addLocalMessages([text], from));
    };
}

export function addLocalMessages(texts: string[], from: From) {
    return (dispatch: ReduxDispatch) => {
        const messages: Message[] = texts.map((text) => {
            return {
                id: uuid(),
                text,
                url: null,
                type: MessageType.TEXT,
                from,
                timestamp: new Date().toISOString(),
            };
        });
        dispatch(addMessages(messages));
    };
}
