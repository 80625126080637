/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { ReactElement } from "react";
import * as QuestionTypes from "../../../constants/QuestionTypes";
import useSelector from "../../../hooks/useSelector";
import FreeTextInput from "./freeTextInput/FreeTextInput";
import LongList from "./longList/LongList";
import MultipleChoice from "./mulitpleChoice/MultipleChoice";
import Ranking from "./ranking/Ranking";
import styles from "./ResponseBar.module.scss";
import SingleChoice from "./singleChoice/SingleChoice";
import EmojiRating from "./emojiRating/EmojiRating";
import RatingQuestion from "./ratingQuestion/RatingQuestion";
import NPSResponseBar from "../npsResponseBar/NPSResponseBar";
import getUrlParams from "../../../constants/getUrlParams";
import classnames from "classnames";
import MultiAttributeQuestion from "../multiAttributeQuestion/MultiAttributeQuestion";

export default function ResponseBar() {
    const action = useSelector((state) => state.action);
    const messages = useSelector((state) => state.messages);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { preview_survey_id } = getUrlParams();
    const mobileView = !!((useSelector((state) => state.info.mobileToggle)) && preview_survey_id);
    let content: ReactElement | null = null;
    if (!action) {
        return null;
    }

    let mobile = {};
    if (mobileView) {
        mobile = styles.mobile;
    }
    let index = messages!.length - 1;
    let lastMessageGroup = messages![index];
    const { questionType } = action;
    switch (questionType) {
        case QuestionTypes.SINGLE_CHOICE: {
            content = <SingleChoice action={action} mobileView={mobileView} />;
            break;
        }
        case QuestionTypes.MULTI_CHOICE: {
            content = <MultipleChoice action={action} mobileView={mobileView} />;
            break;
        }
        case QuestionTypes.FREE_TEXT: {
            content = <FreeTextInput action={action} mobileView={mobileView} />;
            break;
        }
        case QuestionTypes.RANKING: {
            content = <Ranking action={action} mobileView={mobileView} />;
            break;
        }
        case QuestionTypes.LONG_FORM_SINGLE_CHOICE:
        case QuestionTypes.LONG_FORM_MULTI_CHOICE: {
            content = <LongList action={action} />;
            break;
        }
        case QuestionTypes.EMOJI_RATING: {
            content = <EmojiRating action={action} mobileView={mobileView} />;
            break;
        }
        case QuestionTypes.RATING: {
            content = <RatingQuestion action={action} mobileView={mobileView} />;
            break;
        }
        case QuestionTypes.NPS: {
            content = <NPSResponseBar action={action} mobileView={mobileView} />;
            break;
        }
        case QuestionTypes.MULTI_ATTRIBUTE: {
            content = <MultiAttributeQuestion action={action} mobileView={mobileView} messages={lastMessageGroup.messages} />;
            break;
        }
        default:
            break;
    }


    return (
        <div className={styles.response_bar}>
            <div className={classnames(styles.response_bar_content, mobile)}>{content}</div>
        </div>
    );
}
