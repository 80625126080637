/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Action from "../../types/Action";
import createAction from "./helpers/createAction";

export enum Types {
    SET_ACTION = "action:SET_ACTION",
}

export const setAction = createAction<Action | null>(Types.SET_ACTION);

export const Actions = {
    [Types.SET_ACTION]: setAction,
};
export type Actions = typeof Actions;
