import PropTypes from "prop-types";
import React, { Component } from "react";
import styles from "./MultiAttributeQuestion.module.scss";
import { addClientMessage, addStickybeakMessage } from "../../../redux/actions/messages";
import { submitAnswer } from "../../../redux/actions/answers";
import { connect } from "react-redux";
import he from "he";

import classnames from "classnames";
import Button from "../button/Button";

let choices = [5];
for (let i = 0; i < 5; i++) {
    choices[i] = { value: i };
}

class MultiAttributeQuestion extends Component {
    static propTypes = {
        onChoiceSelect: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        choices: PropTypes.array,
        mobileView: PropTypes.bool,
        selected: PropTypes.bool,
        action: PropTypes.any,
        messages: PropTypes.any,
    };
    state = {
        selected: [],
        button: "skip",
        submittable: false,
    };

    static defaultProps = {
        onChoiceSelect: () => null,
        choices: [],
        disabled: false,
        selected: false,
        emojiRating: false,
        ratingQuestion: false,
    };

    componentDidMount() {
        const options = [];
        const length = this.props.messages.length - 1;
        for (let i = 0; i < length; i++) {
            options.push(3);
        }
        this.setState({ selected: options });
    }

    onChoiceSelect = (choice, index) => {};
    onSkipPress = () => {
        this.props.dispatch(addClientMessage("Skip"));
        const answer = {
            question: this.props.action.question,
            systemQuestion: false,
            choices: null,
            choice: null,
            answer: null,
            skipped: true,
        };
        this.props.dispatch(submitAnswer(answer));
    };

    onUndo = () => {
        this.setState({ selected: 0, button: "skip" });
    };

    onConfirmPress = () => {
        this.props.dispatch(
            addClientMessage(
                `1 - ${this.props.action.multiAttributeQuestion.leftLabel} - ${this.props.action.multiAttributeQuestion.rightLabel} - 5`,
            ),
        );

        const length = this.props.messages.length;
        const messages = this.props.messages.slice(1, length);
        messages.forEach((m, i) => {
            this.props.dispatch(addStickybeakMessage(m.text));
            this.props.dispatch(addClientMessage(this.state.selected[i].toString()));
        });
        const answer = {
            question: this.props.action.question,
            systemQuestion: false,
            choices: null,
            choice: null,
            answer: null,
            multiAttributeAnswer: this.state.selected,
            skipped: false,
        };
        this.props.dispatch(submitAnswer(answer));
    };

    onBubbleClick = (value, index) => {
        const newSelected = this.state.selected;
        newSelected[index] = value;
        this.setState({ selected: newSelected });
        if (!this.state.submittable) {
            this.setState({ submittable: true });
        }
    };

    renderBubble = (value, index) => {
        const selected = { [styles.selected_bubble]: this.state.selected[index] === value };
        return <div className={classnames(styles.bubble, selected)} onClick={() => this.onBubbleClick(value, index)} />;
    };

    renderSlider = (message, index) => {
        const text = message.text;

        return (
            <div className={styles.slider}>
                <span className={styles.header}>
                    <span>{he.decode(text)}</span>
                </span>
                <div className={styles.meter}>
                    {this.renderBubble(1, index)}

                    <hr />
                    {this.renderBubble(2, index)}

                    <hr />
                    {this.renderBubble(3, index)}
                    <hr />
                    {this.renderBubble(4, index)}
                    <hr />
                    {this.renderBubble(5, index)}
                </div>
                <div className={styles.labels}>
                    <span>{this.props.action.multiAttributeQuestion.leftLabel}</span>
                    <span>{this.props.action.multiAttributeQuestion.rightLabel}</span>
                </div>
            </div>
        );
    };

    render() {
        const mobile = { [styles.mobile]: this.props.mobileView };
        const length = this.props.messages.length;
        const messages = this.props.messages.slice(1, length);
        return (
            <div className={classnames(styles.container, mobile)}>
                <div className={styles.response_layout_control}>
                    {messages.map((m, i) => this.renderSlider(m, i))}
                    <Button disabled={!this.state.submittable} className={styles.submit_button} onClick={this.onConfirmPress}>
                        Confirm
                    </Button>
                </div>
            </div>
        );
    }
}

export default connect()(MultiAttributeQuestion);
