/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/10/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { combineReducers } from "redux";
import action from "./action";
import answers from "./answers";
import info from "./info";
import messages from "./messages";

const reducers = {
    messages,
    answers,
    action,
    info,
};

const root = combineReducers(reducers);

export type ApplicationState = ReturnType<typeof root>;

export default root;
