/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Message, { From } from "../../types/Message";
import { Actions, Types } from "../actions/messages";

export type MessageGroup = {
    from: From;
    messages: Message[];
};

const initialState: MessageGroup[] = [];

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function messages(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.ADD_MESSAGE: {
            const { payload } = action as ActionValueTypes<Types.ADD_MESSAGE>;
            const message = payload;
            if (!message) {
                return state;
            }

            const newState = [...state];

            const { length } = newState;
            let index = length - 1;
            let lastMessageGroup = newState[index];
            if (!lastMessageGroup || (!!lastMessageGroup && lastMessageGroup.from !== message.from)) {
                index = length;
                lastMessageGroup = {
                    from: message.from,
                    messages: [],
                };
            }

            lastMessageGroup.messages.push(message);
            newState[index] = lastMessageGroup;

            return newState;
        }

        case Types.ADD_MESSAGES: {
            const { payload } = action as ActionValueTypes<Types.ADD_MESSAGES>;
            const newState = [...state];
            const messages = payload || [];

            messages.map((message) => {
                const { length } = newState;
                let index = length - 1;
                let lastMessageGroup = newState[index];

                if (!lastMessageGroup || (!!lastMessageGroup && lastMessageGroup.from !== message.from)) {
                    index = length;
                    lastMessageGroup = {
                        from: message.from,
                        messages: [],
                    };
                }

                lastMessageGroup.messages.push(message);
                newState[index] = lastMessageGroup;
                return null;
            });

            return newState;
        }
        default: {
            return state;
        }
    }
}
