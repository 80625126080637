/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import TextBubble from "../../textBubble/TextBubble";
import styles from "./TextBlock.module.scss";

export default class TextBlock extends Component {
    static propTypes = {
        className: PropTypes.string,
        message: PropTypes.object,
        mobileView: PropTypes.bool
    };

    static defaultProps = {
        className: null,
        message: null,
    };

    render() {
        const { message } = this.props;

        if (!message) {
            return null;
        }

        const className = classnames(styles.text_block, this.props.className);
        return <TextBubble className={className} mobileView={this.props.mobileView} text={message.text} isClient={message.from === "CLIENT"} />;
    }
}
