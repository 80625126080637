/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Action from "../../types/Action";
import { Actions, Types } from "../actions/action";

const initialState: Action | null = null;

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function action(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_ACTION: {
            const { payload } = action as ActionValueTypes<Types.SET_ACTION>;
            return payload;
        }
        default: {
            return state;
        }
    }
}
