import ReactGA from "react-ga";


function chatbotEvent(action: string, label?: string, value?: number,): void {

    ReactGA.event({
        category: "Chatbot",
        action,
        label,
        value
    });
}

function pageViewEvent(surveyId: number) {
    window.dataLayer.push({
        "event": "conversion",
        "send_to" : "AW-10871516074/BQnNCL36wZsYEKrX-L8o"
    });
    window.dataLayer.push({
        "event": "pageView",
        surveyId});
}

function firstQuestionAnsweredEvent(surveyId: number) {
    window.dataLayer.push({
        "event": "firstQuestionAnswered",
        surveyId
    });
}

function thirdQuestionAnsweredEvent(surveyId: number) {
    window.dataLayer.push({
        "event": "conversion",
        "send_to": "AW-10871516074/djlzCLr6wZsYEKrX-L8o",
        "transaction_id": ""
    });
    window.dataLayer.push({
        "event": "thirdQuestionAnswered",
        surveyId
    });
}

function surveyCompletedEvent(surveyId: number) {
    window.dataLayer.push({
        "event": "conversion",
        "send_to": "AW-10871516074/AHa7CMD6wZsYEKrX-L8o"
    });
    window.dataLayer.push({
        "event": "thirdQuestionAnswered",
        surveyId
    });
}

const AnalyticsAPI = {
    chatbotEvent,
    pageViewEvent,
    firstQuestionAnsweredEvent,
    thirdQuestionAnsweredEvent,
    surveyCompletedEvent
};

export default AnalyticsAPI;
