/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Translate Digital
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 * */

import MatButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import classnames from "classnames";
import React from "react";
import styles from "./Button.module.scss";

type Props = {
    plain?: boolean;
    bubble?: boolean;
    round?: boolean;
    darkBlue?: boolean;
    green?: boolean;
} & MuiButtonProps;

export default function Button(props: Props) {
    const { plain, bubble, disabled, round, darkBlue, green, ...otherProps } = props;

    const className = classnames(
        styles.button,
        {
            [styles.disabled]: disabled,
            [styles.plain]: plain || bubble,
            [styles.bubble]: bubble,
            [styles.round]: round,
            [styles.darkBlue]: darkBlue,
            [styles.green]: green,
        },
        props.className,
    );

    return <MatButton {...otherProps} className={className} disabled={disabled} />;
}
