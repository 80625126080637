/* eslint-disable jsx-a11y/no-autofocus */
import classNames from "classnames";
import React, { useState } from "react";
import useDispatch from "../../../../hooks/useDispatch";
import { submitAnswer } from "../../../../redux/actions/answers";
import { addClientMessage } from "../../../../redux/actions/messages";
import Action from "../../../../types/Action";
import styles from "./FreeTextInput.module.scss";
import Button from "../../button/Button";
import { ReactComponent as Undo } from "../../../../resources/svg/undo.svg";
import { ReactComponent as UndoBlank } from "../../../../resources/svg/undo_blank.svg";

type Props = {
    action: Action;
    mobileView: any;
};

export default function FreeTextInput(props: Props) {
    const { action, mobileView } = props;

    const dispatch = useDispatch();
    const [text, setText] = useState("");

    const onFreeTextSubmit = () => {
        dispatch(addClientMessage(text));
        const answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choices: null,
            choice: null,
            answer: text,
            skipped: false,
        };
        dispatch(submitAnswer(answer));
    };

    const onSkipPress = () => {
        dispatch(addClientMessage("Skip"));
        const answer = {
            question: action.question,
            systemQuestion: false,
            choices: null,
            choice: null,
            answer: null,
            skipped: true,
        };
        dispatch(submitAnswer(answer));
    };

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = e;
        let t = "";
        if (target) {
            t = target.value;
        }

        setText(t);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (text && (e.keyCode === 13 || e.key === "Enter")) {
            onFreeTextSubmit();
        }
    };

    const onUndo = () => {
        setText("");
    };

    return (
        <div className={classNames(styles.message_item_container, {[styles.mobile_view]: mobileView})}>
            <div className={styles.response_layout_control}>
                <div className={styles.undo}>{text ? <Undo onClick={onUndo} /> : <UndoBlank />}</div>
                <div className={styles.message_item}>
                    <input
                        value={text}
                        data-hj-whitelist
                        className={classNames(styles.message_text, "data-hj-whitelist", {[styles.mobile]: mobileView})}
                        onChange={onTextChange}
                        onKeyDown={onKeyDown}
                        placeholder={"Type here"}
                        autoFocus
                    />
                </div>
                <div className={styles.skip_send_toggle}>
                    {!text && action.canSkip ? <Button plain onClick={onSkipPress}>Skip</Button> :
                        !!text ? <Button green round onClick={onFreeTextSubmit}>Send</Button> : null}
                </div>
            </div>
        </div>
    );
}
