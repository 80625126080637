/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 13/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

Object.defineProperty(HTMLMediaElement.prototype, "playing", {
    get () {
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    },
});
