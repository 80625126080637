/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-02-13.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import { setAction } from "./action";
import * as ChatBotAPI from "./api/chatbot";
import wrapper from "./helpers/wrapper";
import { setCurrentQuestion, setNumberOfQuestions } from "./info";
import { addMessages } from "./messages";

export const beginChatbot = wrapper(ChatBotAPI.beginChatbotAPI, (dispatch, payload) => {
    dispatch(setNumberOfQuestions(payload.questionNumber));
    dispatch(setCurrentQuestion(1));
    dispatch(addMessages(payload.messages));
    dispatch(setAction(payload.action));
});

export const visitorCount = wrapper(ChatBotAPI.visitorCountAPI);

export const answerQuestion = wrapper(ChatBotAPI.answerQuestionAPI, (dispatch, payload) => {
    if (payload.questionNumber) {
        dispatch(setCurrentQuestion(payload.questionNumber + 1));
    }
    dispatch(addMessages(payload.messages));
    dispatch(setAction(payload.action));
});
