/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import useDispatch from "../../../../hooks/useDispatch";
import useSelector from "../../../../hooks/useSelector";
import { submitAnswer } from "../../../../redux/actions/answers";
import { addClientMessage, addClientMessages } from "../../../../redux/actions/messages";
import Action, { QuestionType } from "../../../../types/Action";
import Answer from "../../../../types/Answer";
import ChoiceDTO from "../../../../types/Choice";
import { From } from "../../../../types/Message";
import Button from "../../button/Button";
import MessageBlock from "../../messages/components/MessageBlock";
import Choice from "../choice/Choice";
import styles from "./LongList.module.scss";
import { ReactComponent as Undo } from "../../../../resources/svg/undo.svg";
import { ReactComponent as UndoBlank } from "../../../../resources/svg/undo_blank.svg";

type Props = {
    action: Action;
};

export default function LongList(props: Props) {
    const messageGroup = useSelector((state) => state.messages[state.messages.length - 1]);

    const { action } = props;
    const { questionType, choices } = action;

    const dispatch = useDispatch();
    const [selected, setSelected] = useState<number[]>([]);
    const [showScrollMore, setShowScrollMore] = useState(false);
    const isMultiChoice = questionType === QuestionType.LONG_FORM_MULTI_CHOICE;


    useEffect(() => {

        const objDiv = document.getElementById("long-list-scroll");
        if (objDiv && (objDiv.clientHeight) < (objDiv.scrollHeight)) {
            setShowScrollMore(true);
        }
    }, []);

    const onChoicePress = (choice: ChoiceDTO) => {
        const choiceId = choice.id;
        const index = selected.indexOf(choiceId);

        if (!isMultiChoice) {
            if (index === -1) {
                setSelected([choiceId]);
            } else {
                setSelected([]);
            }
            return;
        }

        if (index === -1) {
            setSelected((state) => state.concat(choiceId));
        } else {
            setSelected((state) => {
                const newState = [...state];
                newState.splice(index, 1);
                return newState;
            });
        }
    };

    const onConfirmPress = () => {
        const messages = action.choices.filter((c) => selected.indexOf(c.id) !== -1).map((choice) => choice.value);
        dispatch(addClientMessages(messages));

        let selectedChoice = null;
        let selectedChoices = null;

        if (isMultiChoice) {
            selectedChoices = selected;
        } else {
            selectedChoice = selected[0];
        }

        const answer: Answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choices: selectedChoices,
            choice: selectedChoice,
            answer: null,
            skipped: false,
        };

        dispatch(submitAnswer(answer));
    };

    const onUndo = () => {
        setSelected([]);
    };

    const onSkipPress = () => {
        if (!action) {
            return;
        }
        dispatch(addClientMessage("Skip"));
        const answer: Answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choices: null,
            choice: null,
            answer: null,
            skipped: true,
        };
        dispatch(submitAnswer(answer));
    };

    return (
        <div className={styles.full_screen_takeover}>
            <div className={styles.long_list_container}>
                <div className={styles.fixed_header}>
                    <MessageBlock
                        messageGroup={{
                            from: From.STICKYBEAK,
                            messages: [messageGroup.messages[messageGroup.messages.length - 1]],
                        }}
                    />
                    <div className={styles.response_layout_control}>
                        <div className={styles.undo}>{selected.length ? <Undo onClick={onUndo} /> : <UndoBlank />}</div>
                        <div className={styles.response_bar_content}>
                            {isMultiChoice ? <span>You can select more than one</span> : null}
                        </div>
                        <div className={styles.skip_send_toggle}>
                            {!selected.length && action.canSkip ? (
                                <Button plain onClick={onSkipPress}>
                                    Skip
                                </Button>
                            ) : !!selected.length ? (
                                <Button green round onClick={onConfirmPress}>
                                    Send
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className={styles.scrollable_container}>
                    <div className={styles.choices}>
                        {choices.map((choice) => (
                            <Choice
                                key={choice.id}
                                choice={choice}
                                selected={selected.indexOf(choice.id) !== -1}
                                onPress={onChoicePress}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
