import Button from "@material-ui/core/Button";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styles from "./ResponseBubble.module.scss";

export default class ResponseBubble extends Component {
    static propTypes = {
        choice: PropTypes.object.isRequired,
        onChoiceSelect: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        selected: PropTypes.bool,
        emojiRating: PropTypes.bool,
        ratingQuestion: PropTypes.bool,
        mobileView: PropTypes.any
    };

    static defaultProps = {
        choice: {},
        onChoiceSelect: () => null,
        disabled: false,
        selected: false,
        emojiRating: false,
        ratingQuestion: false
    };

    onChoiceSelect = () => {
        this.props.onChoiceSelect(this.props.choice);
    };

    render() {
        if (!this.props.choice.value) {
            return null;
        }
        const className = classnames(styles.response_bubble, {
            [styles.selected]: this.props.selected,
            [styles.mobile_text]: this.props.mobileView

        });

        const mobileClassName = classnames(styles.choice_bubble, {
            [styles.mobile]: this.props.mobileView
        });

        if (this.props.emojiRating) {
            return (
                <div className={styles.choice_wrapper} onClick={this.onChoiceSelect}>
                    <div className={mobileClassName}>
                        <div className={styles.choice_icon}>
                            <img src={this.props.choice.imageUrl} width={55} />
                        </div>
                        <div className={styles.text_container}>
                            <span className={styles.choice_text}>
                                {this.props.choice.value}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }
        return (

            <Button className={className} onClick={this.onChoiceSelect} disabled={this.props.disabled}>
                <span dangerouslySetInnerHTML={{ __html: this.props.choice.value }} />
            </Button>
        );

    }
}
