/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-02-13.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React from "react";
import dumbbells_alt from "../../../resources/mp4/dumbbells_alt.mp4";
import styles from "./Loading.module.scss";

export default function Loading() {
    return (
        <div className={styles.loading}>
            <div className={styles.loading_animation}>
                <video autoPlay loop muted playsInline disableremoteplayback={"true"}>
                    <source src={dumbbells_alt} type={"video/mp4"} />
                    Your browser does not support the video tag.
                </video>
            </div>
            <h4>Loading</h4>
        </div>
    );
}
