import React from "react";
import useDispatch from "../../../../hooks/useDispatch";
import { submitAnswer } from "../../../../redux/actions/answers";
import { addClientMessage } from "../../../../redux/actions/messages";
import Action from "../../../../types/Action";
import Answer from "../../../../types/Answer";
import Choice from "../../../../types/Choice";
import ResponseBubble from "../../responseBarBubble/ResponseBubble";
import styles from "./EmojiRating.module.scss";
import useSelector from "../../../../hooks/useSelector";
import getUrlParams from "../../../../constants/getUrlParams";

type Props = {
    action: Action;
    mobileView: any
};

export default function EmojiRating(props: Props) {
    const { action, mobileView } = props;

    const dispatch = useDispatch();


    const onChoiceSelect = (choice: Choice) => {
        const message = choice.value;
        dispatch(addClientMessage(message));

        const answer: Answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choice: choice.id,
            answer: null,
            choices: null,
            skipped: false,
        };

        dispatch(submitAnswer(answer));
    };

    if (!action.choices.length) {
        return null;
    }
    return (
        <div className={styles.single_choice}>
            {action.choices.map((choice, i) => (
                <ResponseBubble key={i} choice={choice} emojiRating mobileView={mobileView} onChoiceSelect={onChoiceSelect} />
            ))}
        </div>
    );
}
