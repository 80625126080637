/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-03-01.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import classnames from "classnames";
import React from "react";
import TextBubbleLoader from "../../textBubble/TextBubbleLoader";
import styles from "./LoadingBlock.module.scss";

export default function LoadingBlock(props) {
    let mobile = {};
    if (props.mobileView) {
        mobile = styles.mobile;
    }
    const className = classnames(styles.loading_block, props.className, mobile);
    return <TextBubbleLoader className={className} />;
}
