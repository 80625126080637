import Choice from "./Choice";

export enum QuestionType {
    SINGLE_CHOICE = "SINGLE_CHOICE",
    MULTI_CHOICE = "MULTI_CHOICE",
    FREE_TEXT = "FREE_TEXT",
    LONG_FORM_SINGLE_CHOICE = "LONG_FORM_SINGLE_CHOICE",
    LONG_FORM_MULTI_CHOICE = "LONG_FORM_MULTI_CHOICE",
    IMAGE_COMPARE = "IMAGE_COMPARE",
    COMMENT = "COMMENT",
    RANKING = "RANKING",
    EMOJI_RATING = "EMOJI_RATING",
    RATING = "RATING",
    NPS = "NPS",
    END = "END",
    MULTI_ATTRIBUTE="MULTI_ATTRIBUTE"
}

export enum ActionType {
    EMAIL_CAPTURE = "EMAIL_CAPTURE",
    MARKETING_OPT_OUT = "MARKETING_OPT_OUT",
    OXFAM_DONATION = "OXFAM_DONATION",
}

interface Action {
    survey: number;
    question: number;
    systemQuestion: boolean;
    questionType: QuestionType;
    actionType: ActionType;
    choices: Choice[];
    canSkip: boolean;
    multiAttributeQuestion?:any;
}

export default Action;
