/* eslint-disable no-param-reassign */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import qs from "qs";
import getUrlParams from "../../../../constants/getUrlParams";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    },
    paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
    },
});

axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        const urlParams = getUrlParams();
        const { token } = urlParams;
        config.params = {
            ...config.params,
            ...urlParams,
        };

        if (token) {
            (config.headers || {}).Authorization = token.toString();
        }

        return config;
    },
    (err: any) => {
        return Promise.reject(err);
    },
);

export default axiosInstance;
