import React from "react";
import useDispatch from "../../../../hooks/useDispatch";
import { submitAnswer } from "../../../../redux/actions/answers";
import { addClientMessage } from "../../../../redux/actions/messages";
import Action from "../../../../types/Action";
import Answer from "../../../../types/Answer";
import Choice from "../../../../types/Choice";
import ResponseBubble from "../../responseBarBubble/ResponseBubble";
import styles from "./RatingQuestion.module.scss";
import RatingResponseBubble from "../../ratingResponseBubble/RatingResponseBubble";
import PropTypes from "prop-types";

type Props = {
    action: Action;
    mobileView: any,

};

export default function RatingQuestion(props: Props) {
    const { action, mobileView } = props;

    const dispatch = useDispatch();

    const onChoiceSelect = (choice: Choice) => {
        const message = choice.value;
        dispatch(addClientMessage(message));

        const answer: Answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choice: choice.id,
            answer: null,
            choices: null,
            skipped: false,
        };

        dispatch(submitAnswer(answer));
    };

    if (!action.choices.length) {
        return null;
    }
    return (
        <div className={styles.single_choice}>
            <RatingResponseBubble choices={action.choices}
                                  action={action}
                                  mobileView={mobileView}
                                  ratingQuestion
                                  onChoiceSelect={onChoiceSelect} />
        </div>
    );
}
