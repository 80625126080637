// eslint-disable no-empty-pattern
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../../reducers/root";

export type DispatchFunctionSuccess<R, P extends Parameters<any>> = (
    dispatch: ThunkDispatch<ApplicationState, {}, AnyAction>,
    data: R,
    getState: () => ApplicationState,
    ...params: Partial<P>
) => void;

export type DispatchFunctionFailure<P extends Parameters<any>> = (
    dispatch: ThunkDispatch<ApplicationState, {}, AnyAction>,
    data: unknown,
    getState: () => ApplicationState,
    ...params: Partial<P>
) => void;

// Called at build time
export default function wrapper<R, P extends Parameters<any>>(
    promise: (...args: P) => Promise<R>,
    onDispatchSuccess?: DispatchFunctionSuccess<R, P>,
    onDispatchFailure?: DispatchFunctionFailure<P>,
) {
    // Called when creating the action with the params during runtime
    return (...params: Parameters<typeof promise>): ThunkAction<Promise<R>, ApplicationState, R, AnyAction> => {
        // Thunk action
        return async (dispatch, getState): Promise<R> => {
            try {
                const data = await promise(...params);

                if (process.env.NODE_ENV === "development") {
                    console.log("API SUCCESS: ", data);
                }

                if (onDispatchSuccess) {
                    onDispatchSuccess(dispatch, data, getState, ...params);
                }

                return data;
            } catch (err) {
                let error = err;
                if (!!err.response && !!err.response.data) {
                    error = err.response.data;
                }

                if (process.env.NODE_ENV === "development") {
                    console.log("API FAILURE: ", error.message);
                }

                if (onDispatchFailure) {
                    onDispatchFailure(dispatch, error, getState, ...params);
                }

                throw error;
            }
        };
    };
}
