/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { useState } from "react";
import { arrayMove, SortableContainer, SortableElement, SortEnd } from "react-sortable-hoc";
import useDispatch from "../../../../hooks/useDispatch";
import useSelector from "../../../../hooks/useSelector";
import { submitAnswer } from "../../../../redux/actions/answers";
import { addClientMessages } from "../../../../redux/actions/messages";
import Action from "../../../../types/Action";
import Answer from "../../../../types/Answer";
import ChoiceDTO from "../../../../types/Choice";
import { From } from "../../../../types/Message";
import Button from "../../button/Button";
import MessageBlock from "../../messages/components/MessageBlock";
import Choice from "../choice/Choice";
import styles from "./Ranking.module.scss";

type Props = {
    action: Action;
    mobileView: boolean;
};

const SortableItem = SortableElement(({ choice, choiceIndex }: { choice: ChoiceDTO; choiceIndex: number }) => {
    return <Choice key={choice.id} choice={choice} draggable />;
});

const SortableList = SortableContainer(({ items, choices }: { items: number[]; choices: ChoiceDTO[] }) => (
    <div className={styles.choices}>
        {items.map((choiceId, choiceIndex) => (
            <SortableItem
                key={choiceId}
                index={choiceIndex}
                choice={choices.filter((c) => c.id === choiceId)[0]}
                choiceIndex={choiceIndex}
            />
        ))}
    </div>
));

export default function Ranking(props: Props) {
    const messageGroup = useSelector((state) => state.messages[state.messages.length - 1]);

    const { action, mobileView } = props;
    const { choices } = action;

    const dispatch = useDispatch();
    const [orderedChoices, setOrderedChoices] = useState(choices.map((c) => c.id));

    const onConfirmPress = () => {
        const messages = orderedChoices.map((choiceId) => action.choices.filter((c) => c.id === choiceId)[0]).map((choice) => choice.value);
        dispatch(addClientMessages(messages));

        const answer: Answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choices: orderedChoices,
            choice: null,
            answer: null,
            skipped: false,
        };

        dispatch(submitAnswer(answer));
    };

    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        const newOrderedChoices = arrayMove([...orderedChoices], oldIndex, newIndex);
        setOrderedChoices(newOrderedChoices);
    };

    return (
        <div className={styles.long_list_container}>
            <div className={styles.inner}>
                <div className={styles.long_list}>
                    <div className={styles.message_block}>
                        <MessageBlock
                            messageGroup={{
                                from: From.STICKYBEAK,
                                messages: [messageGroup.messages[messageGroup.messages.length - 1]],
                            }}
                        />
                    </div>
                    <div className={styles.divider}>
                        <div className={styles.divider_line} />
                        <span>Drag rows to set ranking...</span>
                        <div className={styles.divider_line} />
                    </div>
                    <SortableList
                        items={orderedChoices}
                        choices={action.choices}
                        helperClass={styles.helper}
                        axis={"y"}
                        onSortEnd={onSortEnd}
                    />
                </div>
            </div>
            <div className={styles.button_wrapper}>
                <Button round darkBlue className={styles.button} onClick={onConfirmPress}>
                    Confirm
                </Button>
            </div>
        </div>
    );
}
