import React, { useState } from "react";
import useDispatch from "../../../../hooks/useDispatch";
import { submitAnswer } from "../../../../redux/actions/answers";
import { addClientMessage, addClientMessages } from "../../../../redux/actions/messages";
import Action from "../../../../types/Action";
import Answer from "../../../../types/Answer";
import Choice from "../../../../types/Choice";
import ResponseBubble from "../../responseBarBubble/ResponseBubble";
import styles from "./MultipleChoice.module.scss";
import Button from "../../button/Button";
import { ReactComponent as Undo } from "../../../../resources/svg/undo.svg";
import { ReactComponent as UndoBlank } from "../../../../resources/svg/undo_blank.svg";

type Props = {
    action: Action;
    mobileView:any;
};

export default function MultipleChoice(props: Props) {
    const { action, mobileView } = props;
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<number[]>([]);

    const onChoiceSelect = (choice: Choice) => {
        const choiceId = choice.id;

        const index = selected.indexOf(choiceId);
        if (index === -1) {
            setSelected((state) => state.concat(choiceId));
        } else {
            setSelected((state) => {
                const newState = [...state];

                newState.splice(index, 1);
                console.log("newState", newState);
                return newState;
            });
        }
    };

    const onSubmitMultiChoice = () => {
        const choices = selected;
        const messages = action.choices.filter((c) => choices.indexOf(c.id) !== -1).map((choice) => choice.value);
        dispatch(addClientMessages(messages));

        const answer: Answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choices,
            answer: null,
            choice: null,
            skipped: false,
        };

        dispatch(submitAnswer(answer));
    };

    const onUndo = () => {
        setSelected([]);
    };

    const onSkipPress = () => {
        if (!action) {
            return;
        }
        dispatch(addClientMessage("Skip"));
        const answer: Answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choices: null,
            choice: null,
            answer: null,
            skipped: true,
        };
        dispatch(submitAnswer(answer));
    };

    if (!action.choices.length) {
        return null;
    }

    return (
        <div className={styles.multiple_choice}>
            <div className={styles.response_layout_control}>
                <div className={styles.undo}>{selected.length ? <Undo onClick={onUndo} /> : <UndoBlank />}</div>
                <div className={styles.response_bar_content}>
                    {action.choices.map((choice, i) => (
                        <ResponseBubble
                            key={i}
                            mobileView={mobileView}
                            selected={selected.indexOf(choice.id) !== -1}
                            choice={choice}
                            onChoiceSelect={onChoiceSelect}
                        />
                    ))}
                </div>
                <div className={styles.skip_send_toggle}>
                    {!selected.length && action.canSkip ? <Button plain onClick={onSkipPress}>Skip</Button> :
                        !!selected.length ? <Button green round onClick={onSubmitMultiChoice}>Send</Button> : null}
                </div>
            </div>
        </div>
    );
}
