/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Translate Digital
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Suspense, useEffect, useState } from "react";
import useDispatch from "../../../hooks/useDispatch";
import useSelector from "../../../hooks/useSelector";
import { submitAnswer } from "../../../redux/actions/answers";
import { addClientMessage } from "../../../redux/actions/messages";
import Answer from "../../../types/Answer";
import Button from "../button/Button";
import styles from "./Toolbar.module.scss";
import { ReactComponent as Menu } from "./../../../resources/svg/info_icon.svg";
import { setLoading, setMobileToggle } from "../../../redux/actions/info";
import getUrlParams from "../../../constants/getUrlParams";

const Drawer = React.lazy(() => import("../drawer/Drawer"));

type Props = {};

export default function Toolbar(props: Props) {
    const dispatch = useDispatch();
    const info = useSelector((state) => state.info);
    const action = useSelector((state) => state.action);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { preview_survey_id } = getUrlParams();

    const { currentQuestion, numberOfQuestions } = info;

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const onAboutPress = () => {
        setDrawerOpen(true);
    };
    const toggleMobileView = () => {
        dispatch(setMobileToggle(!info.mobileToggle));
    };
    const onDrawerClose = () => {
        setDrawerOpen(false);
    };

    useEffect(() => {
        dispatch(setMobileToggle(true));
    }, []);

    const onSkipPress = () => {
        if (!action) {
            return;
        }
        dispatch(addClientMessage("Skip"));
        const answer: Answer = {
            question: action.question,
            systemQuestion: action.systemQuestion,
            choices: null,
            choice: null,
            answer: null,
            skipped: true,
        };
        dispatch(submitAnswer(answer));
    };

    return (
        <>
            <div className={styles.toolbar}>
                <div className={styles.content}>
                    <div className={styles.left} onClick={onAboutPress}>
                        <Menu />
                    </div>
                    {preview_survey_id && (
                        <Button className={styles.button}
                                onClick={toggleMobileView}
                        >
                            {`Switch to ${info.mobileToggle ? "desktop" : "mobile"} view`}
                        </Button>
                    )}
                </div>
            </div>

            <Suspense fallback={null}>
                <Drawer isOpen={isDrawerOpen} onClose={onDrawerClose} />
            </Suspense>
        </>
    );
}
