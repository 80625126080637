import "core-js/features/map";
import "core-js/features/set";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./constants/prototypes";
import "./index.scss";

ReactDOM.render(<App />, document.getElementById("root"));
