/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import useSelector from "../../../hooks/useSelector";
import Message, { From, MessageType } from "../../../types/Message";
import MessageBlock from "./components/MessageBlock";
import styles from "./Messages.module.scss";
import { QuestionType } from "../../../types/Action";

type Props = {};

export default function Messages(props: Props) {
    const messages = useSelector((state) => state.messages);
    const action = useSelector((state) => state.action);
    const [multiAttributes, setMultiAttributes] = useState<number[]>([]);
    const loading = useSelector((state) => state.info.loading);

    useEffect(()=>{
        if((action!||{}).questionType==="MULTI_ATTRIBUTE"){
            const newMultiAttributes = multiAttributes;
            newMultiAttributes.push(index);
            setMultiAttributes(newMultiAttributes);
        }
    }, [action, ]);

    const allMessages = messages.map((messageGroup) => ({
        ...messageGroup,
        messages: [...messageGroup.messages],
    }));

    const { length } = allMessages;
    let index = allMessages.length - 1;
    let lastMessageGroup = allMessages[index];
    if (loading) {
        const message: Message = {
            id: uuid(),
            type: MessageType.LOADING,
            from: From.STICKYBEAK,
            timestamp: new Date().toISOString(),
            url: null,
            text: null,
        };

        if (!lastMessageGroup || lastMessageGroup.from === From.CLIENT) {
            index = length;
            lastMessageGroup = {
                from: From.STICKYBEAK,
                messages: [],
            };
        }

        const messageGroupMessages = lastMessageGroup.messages;
        messageGroupMessages.push(message);
        lastMessageGroup.messages = messageGroupMessages;

        allMessages[index] = {
            ...lastMessageGroup,
        };
    }


    return (
        <div className={styles.messages_container}>
            <div className={styles.messages}>
                {allMessages.map((messageGroup, i) => (

                        <MessageBlock messageIndex={i} key={i} messageGroup={messageGroup} multiAttributes={multiAttributes} action={action}/>

                ))}
            </div>
        </div>
    );
}
