/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/05/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { v4 as uuid } from "uuid";

declare global{
    interface Window {
        deviceId: string,
        dataLayer: any[]
    }
}

export default async function setupDevice() {
    window.deviceId = await localStorage.getItem("deviceId")!;
    if (!window.deviceId) {
        window.deviceId = uuid();
        await localStorage.setItem("deviceId", window.deviceId);
    }
}
