/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/05/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import qs from "qs";

export default function getUrlParams() {
    // eslint-disable-next-line no-undef
    const params = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    if (params.params) {
        params.params = (params.params || "").split(" ").join("+");
    }

    return params;
}
