import PropTypes from "prop-types";
import React, { Component } from "react";
import styles from "./NPSResponseBar.module.scss";
import { addClientMessage } from "../../../redux/actions/messages";
import { submitAnswer } from "../../../redux/actions/answers";
import { connect } from "react-redux";
import { ReactComponent as Undo } from "../../../resources/svg/undo.svg";
import { ReactComponent as UndoBlank } from "../../../resources/svg/undo_blank.svg";
import Button from "../button/Button";
import classnames from "classnames";

let choices = [11];
for (let i = 0; i < 11; i++) {
    choices[i] = { value: i };
}

class NPSResponseBar extends Component {
    static propTypes = {
        onChoiceSelect: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        choices: PropTypes.array,
        mobileView: PropTypes.bool,
        selected: PropTypes.bool,
        nps: PropTypes.bool,
        action: PropTypes.any
    };
    state = {
        selected: 0,
        button: "skip"
    };

    static defaultProps = {
        onChoiceSelect: () => null,
        choices: [],
        disabled: false,
        selected: false,
        emojiRating: false,
        ratingQuestion: false
    };

    onChoiceSelect = (choice, index) => {
        this.setState({ selected: index + 1, button: "confirm" });
    };
    onSkipPress = () => {
        this.props.dispatch(addClientMessage("Skip"));
        const answer = {
            question: this.props.action.question,
            systemQuestion: false,
            choices: null,
            choice: null,
            answer: null,
            skipped: true,
        };
        this.props.dispatch(submitAnswer(answer));
    };

    onUndo = () => {
        this.setState({ selected: 0, button: "skip" });
    };

    onConfirmPress = () => {
        this.props.dispatch(addClientMessage((this.state.selected - 1).toString()));
        const answer = {
            question: this.props.action.question,
            systemQuestion: false,
            choices: null,
            choice: this.props.action.choices[this.state.selected - 1].id,
            answer: (this.state.selected - 1).toString(),
            skipped: false,
        };
        this.props.dispatch(submitAnswer(answer));
    };

    renderScoreBubbles = () => {
        return (

            (choices).map((choice, index) => {
                return (
                    <div
                        className={this.state.selected >= index + 1 ? styles.score_bubble_selected : styles.score_bubble}
                        key={`rating-${index + 1}`}>
                        <div className={styles.box_shape} onClick={() => this.onChoiceSelect(choice, index)}>
                            <div className={styles.score_value}>
                                <div className={styles.score_value_text}>
                                    {choice.value}
                                </div>
                            </div>
                        </div>
                    </div>

                );
            })

        );
    };


    render() {
        const mobile = { [styles.mobile]: this.props.mobileView };

        return (
            <div className={classnames(styles.container, mobile)}>
                <div className={styles.response_layout_control}>
                    <div className={styles.undo}>{this.state.button === "confirm" ? <Undo onClick={this.onUndo} /> :
                        <UndoBlank />}</div>
                    <div className={styles.response_bar_content}>
                        <div className={styles.header}>
                            <span className={styles.label}>
                                Very unlikely
                            </span>
                            <span className={styles.label}>
                                Very likely
                            </span>
                        </div>
                        <div className={styles.score_bubbles}>
                            {this.renderScoreBubbles()}
                        </div>
                    </div>
                    <div className={styles.skip_send_toggle}>
                        {this.state.button !== "confirm" && this.props.action.canSkip ? (
                                <Button plain onClick={this.onSkipPress}>Skip</Button>
                            ) :
                            this.state.selected > 0 && (
                                <Button green round
                                        onClick={this.state.selected > 0 ? this.onConfirmPress : null}>
                                    Send
                                </Button>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(NPSResponseBar);
