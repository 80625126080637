/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Answer from "../../types/Answer";
import { Actions, Types } from "../actions/answers";

const initialState: Answer[] = [];

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function answers(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.ADD_ANSWER: {
            const { payload } = action as ActionValueTypes<Types.ADD_ANSWER>;
            const newState = [...state];
            newState.push(payload);
            return newState;
        }
        default: {
            return state;
        }
    }
}
